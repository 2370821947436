@import '../../../../theme/base.scss';

.sidebar-container {
  padding: $outer-section-padding;
  min-width: $sidebar-width;
  box-sizing: border-box; // DO NOT CHANGE, IT WILL BREAK THE .page-content styles
  height: 100vh;
  position: fixed;
  background: $light-overlay-color;
  border-right: solid 5px $border-color;
  svg {
    width: 30px;
    height: 30px;
  }
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
}

.links-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  max-height: 100vh;
  flex-direction: column;
  margin-top: $outer-section-padding;
  >div + div {
    margin-top: $outer-section-padding;
  }
}

@media screen and (max-width: $mobile-break) {

  .closed-sidebar-container {
    background: $light-color;
    position: fixed;
    z-index: 99;
  }

  .sidebar-container {
    background: $light-color;
    z-index: 99;
    position: absolute;
    width: 100vw;
    height: 100vh;
  }
}
