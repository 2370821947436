@import '../../theme/base.scss';

.toaster-floating-container {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

.alert-message-container {
 padding-right: 1rem; 
}
