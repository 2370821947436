@import '../../../theme/_base.scss';

.custom-table {
  td {
    padding: 15px 5px;
  }
  th {
    font-weight: 500;
    color: $border-color;
    text-transform: uppercase;
    font-size: 14px;
    h6 {
      color: $border-color;
    }
  }
}

.custom-body {
  tr {
    a, small {
      color: $text-color;
      text-decoration-color: $border-color;
      &:hover {
        color: $primary-color;
        text-decoration-color: $primary-color;
      }
    }
  }
}
