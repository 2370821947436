@import '../../../theme/base';

.themed-input {
  padding: 5px 10px;
  font-size: 14px;
  border: solid 1px $secondary-color;
}

.bold-label {
  font-weight: 500;
  font-size: 16px !important;
  margin-bottom: 4px;
}
.light-label {
  font-weight: 400;
  font-size: 15px !important;
  margin-bottom: 4px;
}
