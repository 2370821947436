$primary-color: #5A6AFB;
$secondary-color: #0A146B;

$success-color: #2a9d8f;
$error-color: #e74c3c;
$text-color: #222f3e;
$warning-color: #f6c343;
$border-color: #B7C6DA;
$info-color: #3a86ff;
$light-color: #e2edff;
$light-overlay-color: rgba($light-color, 0.3);

// Layout
$layout-border-radius: 3px;
$layout-border: solid 1px $border-color;

$sidebar-width: 300px;
$closed-sidebar-width: 40px;

$outer-section-padding: 1.5rem;
$inner-section-padding: 1rem;

$mobile-break: 768px;
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  line-height: inherit;
}
h5 {
  font-weight: 400;
}
h6 {
  color: $text-color;
  font-weight: bold;
  text-transform: uppercase;
}
a {
  text-underline-offset: 3px;
}
.bottom-border {
  border-bottom: $layout-border;
}

.icon-link {
  font-weight: 500;
  text-decoration: none;
  background: none;
  border: none;
  opacity: 0.7;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
  color: $text-color !important;
  svg {
    margin-right: $inner-section-padding;
  }
  &:hover {
    opacity: 1;
  }
}

.form-label {
  font-size: 13px;
}
.btn-close {
  box-sizing: border-box !important;
  position: relative !important;
}

.no-scroll {
  overflow-y:hidden;
}

@media screen and (max-width: $mobile-break) {
  h6 {
    font-size: 0.8rem;
  }
}
