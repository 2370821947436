@import '../../../theme/_base.scss';

.question-bubble {
  margin-left: 5px;
  transform: translateY(-2px);
  font-size: 11px;
  font-weight: bold;
  border-radius: 50%;
  padding: 5px;
  background-color: $secondary-color;
  line-height: 0.3;
  color: #fff;
  border: solid 1px $secondary-color;
  .tooltip-text {
    visibility: hidden;
    display: block;
    width: auto;
    font-size: 16px;
    font-weight: 400;
    min-width: 300px;
    line-height: 1.2;
    padding: 10px;
    color: $light-color;
    border: solid 1px $secondary-color;
    background-color: $secondary-color !important;
    text-align: left;
    border-radius: 4px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
  }
  &:hover {
    .tooltip-text {
      visibility: visible;
    }
  }
}