@import '../../theme/base.scss';

.page-content {
  padding: $outer-section-padding;
  padding-left: calc($sidebar-width + 2 * $outer-section-padding);
}

.content-header {
  margin-bottom: 1rem;
  display: flex;
  align-items: baseline;
  small {
    margin-left: 1rem;
  }
}

@media screen and (max-width: $mobile-break) {
  .page-content {
    padding: 0;
  }
  .content-body {
    padding: 0 15px;
    padding-top: 5rem;
  }

  .content-header {
    display: none !important; // header is shown on the SideBar container
  }
}
