#googleLogin {
  display: inline-block;
  background: white;
  color: #444;
  width: 190px;
  border-radius: 5px;
  padding: 5px 10px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  white-space: nowrap;
  &:hover {
    cursor: pointer;
  }
  .icon {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
  }
  span.buttonText {
    display: inline-block;
    vertical-align: middle;
    padding-left: 12px;
    font-size: 14px;
    font-weight: bold;
  }
}