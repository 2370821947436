@import '../../../theme/_base.scss';

.btn-primary {
  min-width: 180px;
}
.button {
  letter-spacing: 0.7px;
  a {
    color: inherit !important;
    text-decoration: none;
  }
}
