@import '../styles.scss';

.icon-button {
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
  svg {
    width: 30px;
    height: 30px;
  }
}
