@import '../../../theme/base.scss';

.nav-link {
  @extend .icon-link;
  padding-bottom: 1rem;
  color: $border-color;
  &.active {
    color: $secondary-color !important;
    opacity: 1;
    width: 100%;
    svg {
      path, g {
        fill: $secondary-color !important;
      }
    }
  }
}
